import { useStoreState } from 'easy-peasy';

/**
 * Returns the router from the store
 * @returns {object}
 */
export default function useRouter() {
  const { route, url, active, params, prevViewed, lastViewed, routeName, state } = useStoreState(
    (state) => state.router
  );

  return {
    route,
    state,
    url,
    active,
    params,
    prevViewed,
    lastViewed,
    routeName,
  };
}
