import { useRef } from 'react';
import { useCreateNutshellInstance, useNutshellAuth, useOnloadPostMessage } from '@webfx/web-hooks';
import { Spinner } from '@webfx/core-web';
import styles from './NutshellEmbedFrame.module.css';

/**
 *  @param {object} props - props
 * @param {string} props.token - Nutshell Auth Token
 * @param {string} props.embedURL - embedURL
 * @returns jsx markup
 */
export const NutshellEmbed = ({ token, embedURL }) => {
  const iframeRef = useRef(null);

  useOnloadPostMessage({
    ref: iframeRef,
    message: token,
    targetOrigin: 'https://nutshell.webfx.com/',
  });

  return (
    <iframe
      className={styles.frame}
      ref={iframeRef}
      src={embedURL}
      allow="clipboard-read; clipboard-write"
    ></iframe>
  );
};

/**
 * @param void
 * @returns jsx markup
 */
export const NutshellEmbedFrame = ({
  embedURL,
  siteId = null,
  nsInstanceId = null,
  loadingComponent = <div></div>,
}) => {
  const nutshell = useCreateNutshellInstance();
  const auth = useNutshellAuth(siteId, 'embed');

  if (nutshell.isLoading || auth.isLoading) {
    return <Spinner />;
  }

  if (!nutshell?.data?.nsInstanceId && !nsInstanceId) {
    return loadingComponent;
  }

  const { token } = auth?.data || {};

  if (!token) {
    return loadingComponent;
  }

  return <NutshellEmbed token={token} embedURL={embedURL} />;
};
