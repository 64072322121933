import { useStoreState } from 'easy-peasy';
import { api } from '@webfx/core-web';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import useAuth from './useAuth';

/**
 * Queries and returs the currently active sites data
 * allows passing additional query params to fetch
 * @param {object} [query] optional query params to pass to the api call
 * @returns {object} the active site
 */
export default function useActiveSite(query = {}) {
  const params = useParams();
  const { user, isAdmin } = useAuth();

  const activeSiteId = useStoreState((state) => state.sites.activeId);
  const siteId =
    Number(params.siteId) ||
    activeSiteId ||
    (user?.sites.length === 1 && !isAdmin ? user?.sites?.[0]?.siteId : null); // this enforces single user sites to always be active

  const site = api.useQuery(['sites', siteId, query], {
    enabled: !!siteId,
    placeholderData: () => ({ siteId }),
    select: (data) => data ?? { siteId },
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 60,
  });

  // since we are only returning a subset of the react query object
  // we memo it so rerenders we aren't creating a new object every time
  const memod = useMemo(() => {
    return {
      siteId,
      ...site.data,
      isLoading: site.isLoading,
      isFetched: site.isFetched,
      refetch: site.refetch,
    };
  }, [siteId, site.data, site.isLoading, site.refetch, site.isFetched]);

  return memod;
}
