import DatePicker from 'react-datepicker';
import classnames from 'classnames';
import { Icon } from '@webfx/web-ui';

import styles from './MonthRangePicker.module.css';

const MonthRangePicker = ({
  startDate,
  endDate,
  minDate = null,
  maxDate = null,
  startDateChange,
  endDateChange,
  className,
  disabled,
}) => (
  <div className={classnames(styles.datePicker, 'p-2', className)}>
    <div className="d-flex align-items-center">
      <Icon className={classnames(styles.dateIcon, 'font-24 text-gray-400 mr-2')}>date_range</Icon>
      <div className={styles.startDate} data-fx-name="startDateChange">
        <DatePicker
          selected={startDate}
          onChange={(date) => startDateChange(date)}
          selectsStart
          startDate={startDate}
          endDate={endDate}
          minDate={minDate}
          maxDate={endDate}
          dateFormat="MMM yyyy"
          showMonthYearPicker
          disabled={disabled}
        />
      </div>
      <span className="text-gray-400 mx-2">—</span>
      <div data-fx-name="endDateChange">
        <DatePicker
          selected={endDate}
          onChange={(date) => endDateChange(date)}
          selectsEnd
          startDate={startDate}
          endDate={endDate}
          minDate={startDate}
          maxDate={maxDate}
          dateFormat="MMM yyyy"
          showMonthYearPicker
          disabled={disabled}
        />
      </div>
    </div>
  </div>
);

export default MonthRangePicker;
