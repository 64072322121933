/**
 * Hook to check casl permissions for an array of items with a dynamic length. This is required
 * because useCan is a hook and therefore cannot be called in a loop and must be called the same
 * number of times in each render.
 *
 * Usage:
 * Provide an array of items as arguments as you would pass them to useMulti(...), EX:
 *    useCanMulti([
 *      [ 'read', 'mcfx', { siteId: 5 } ],
 *      [ 'read', 'twfx', { siteId: 5 } ],
 *      ...
 *    ])
 */
import { useAbility } from '@casl/react';
import { subject } from '@casl/ability';
import { PermissionsContext } from '@webfx/core-web';

export function useCanMulti(args) {
  const ability = useAbility(PermissionsContext);
  return args.map(([action, subjectType, object, field]) => {
    if (!object) {
      return ability.can(action, subjectType, field);
    }
    return ability.can(action, subject(subjectType, object), field);
  });
}

export default useCanMulti;
