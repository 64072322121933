import { useAbility } from '@casl/react';
import { subject } from '@casl/ability';
import isPlainObject from 'lodash/isPlainObject';
import { PermissionsContext } from '@webfx/core-web';
const TYPE_FIELD = '__caslSubjectType__';

export function useCan(...args) {
  const ability = useAbility(PermissionsContext);

  // arg 3 must be a property string, if its an object
  // we are passing a subject to validate the object off of
  if (isPlainObject(args[2])) {
    if (!Object.prototype.hasOwnProperty.call(args[2], TYPE_FIELD)) {
      args[2] = subject(args[1], args[2]);
    } else if (args[2][TYPE_FIELD] === args[1]) {
      // noop same subject
    } else {
      delete args[2][TYPE_FIELD];
      args[2] = subject(args[1], args[2]);
    }
    return ability.can(args[0], args[2], args[3]);
  }

  return ability.can(...args);
}

export default useCan;
