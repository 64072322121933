import { action, computed } from 'easy-peasy';
import castToArray from 'lodash/castArray';
import { useEffect } from 'react';
import { BulkSelectionContext } from '@webfx/core-web';

const generateModel = (name) => {
  return {
    name,
    selectedMap: {},
    selected: computed((state) => Object.keys(state.selectedMap)),

    isSelected: computed((state) => (id) => {
      return state.updateAll || !!state.selectedMap[id];
    }),

    allToggled: false,
    updateAll: false,

    add: action((state, payload) => {
      const items = castToArray(payload);

      for (const item of items) {
        state.selectedMap[item] = true;
      }
    }),

    remove: action((state, payload) => {
      const items = castToArray(payload);

      for (const item of items) {
        delete state.selectedMap[item];
      }

      state.updateAll = false;
    }),

    reset: action((state) => {
      state.selectedMap = {};
      state.allToggled = false;
      state.updateAll = false;
    }),

    toggle: action((state, payload) => {
      if (state.selectedMap[payload]) {
        delete state.selectedMap[payload];
        state.allToggled = false;
      } else {
        state.selectedMap[payload] = true;
      }
    }),

    toggleAll: action((state, payload) => {
      if (state.allToggled) {
        state.selectedMap = {};
        state.allToggled = false;
        state.updateAll = false;
      } else {
        state.allToggled = true;
        const items = castToArray(payload);

        for (const item of items) {
          state.selectedMap[item] = true;
        }
      }
    }),

    setUpdateAll: action((state, payload) => {
      state.updateAll = !!payload;
    }),
  };
};

/**
 * Hook to handle bulk action selection for lists.
 *
 * Usage example:
 *
 const bulkdSelectionObject  = useBulkAction('crm/agenda', options);
 *
 * Options:
 *
 * - resetOnMount: Reset selection when component is mounted. Useful if we want to reset the selection when rendering the main component. Defaults to false.
 *
 * - destroyOnUnmount: Remove state from provider when the component is unmounted. Defaults to false.
 *
 * Return values
 * - selectedMap: A map that includes the ids as keys.
 *
 * - selected: A memo array that includes all the ids from selectedMap
 *
 * - allToggled: Identifier to know when all the items where toggled.
 *
 * - updateAll: Identifier to know if the user want to update all the records outside the current selection. This property can be used to mimic Gmail selection feature where we can include all the items that are not event present.
 *
 * - add(items): Adds an item to selectedMap
 *
 * - remove(items): Remove items from selectedMap
 *
 * - toggle(item): Add or remove a single item.
 *
 * - reset(): Remove all the iterms from selectedMap.
 *
 * - toggleAll(items): Add all items and update `allToggled` property.
 *
 * - setUpdateAll(true|false): Sets `updateAll` property
 *
 * @param name
 * @param options
 */
const useBulkSelection = (name, { resetOnMount = false, destroyOnUnmount = false } = {}) => {
  const store = BulkSelectionContext.useStore();

  if (!store.getState()[name]) {
    store.addModel(name, generateModel(name));
  }

  const state = BulkSelectionContext.useStoreState((state) => state[name]);
  const actions = BulkSelectionContext.useStoreActions((actions) => actions[name]);

  useEffect(() => {
    if (resetOnMount) {
      actions.reset();
    }

    return () => {
      if (destroyOnUnmount) {
        store.removeModel(name);
      }
    };
  }, [resetOnMount, destroyOnUnmount]);

  return {
    ...state,
    ...actions,
  };
};

export default useBulkSelection;
