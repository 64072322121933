import { api } from '@webfx/core-web';
import useActiveSite from './useActiveSite';

/**
 * Handles processing plan upgrades
 *
 * @returns {object} - newly updated plan
 */
const useSitePlanMutator = () => {
  const { siteId } = useActiveSite();
  return api.useMutation(['sites', siteId]);
};

export default useSitePlanMutator;
