import { Icon, context } from '@webfx/core-web';
import { useActiveSite, useAuth } from '@webfx/web-hooks';
import classnames from 'classnames';
import { useStoreActions, useStoreState } from 'easy-peasy';
import React, { useMemo } from 'react';
import { Dropdown } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

import ViewAsSelector from '../ViewAsSelector';
import Menu from './Menu';
import Toggle from './Toggle';

import styles from './SiteSelector.module.css';

export const SiteSelector = () => {
  const isAdmin = useAuth().isAdmin;
  const { ResizeContext } = context;
  const { url } = useStoreState((state) => state.router);
  const { setActive } = useStoreActions((actions) => actions.sites);

  const history = useHistory();
  const activeSite = useActiveSite({ $join: ['encryption'] });

  const { encryption: requiresEncryption } = activeSite;

  const toggleLabel = useMemo(() => {
    if (activeSite.siteId === null) {
      return isAdmin ? 'Select a Client' : 'Select a Profile';
    }

    return activeSite.name ?? activeSite.url;
  }, [activeSite, isAdmin]);

  return (
    <div>
      <div id="container-div" className={styles.wrapper}>
        <Dropdown className="h-100">
          <Dropdown.Toggle as={Toggle} data-fx-name="selectClientProfile">
            <span className="flex-grow-1 text-left" data-fx-name="currentClientProfile">
              {toggleLabel}
            </span>
          </Dropdown.Toggle>
          {!!activeSite.siteId && (
            <div
              className={styles.close}
              onClick={() => {
                setActive({ siteId: null });
                history.push(url({ route: 'mcfxDashboard' }));
              }}
            >
              <Icon>close</Icon>
            </div>
          )}
          <ResizeContext.Provider>
            <div className=" position-relative">
              <div className={classnames('bg-danger position-absolute', styles.menuOuter)}>
                <Dropdown.Menu className={styles.menuWrapper}>
                  <Menu />
                </Dropdown.Menu>
              </div>
            </div>
          </ResizeContext.Provider>
        </Dropdown>
      </div>
      {activeSite.siteId !== null &&
        activeSite.companyId &&
        activeSite.companyId !== 373296 &&
        isAdmin &&
        !requiresEncryption && <ViewAsSelector />}
    </div>
  );
};
