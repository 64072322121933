// import { store as origStore } from '@webfx/core-web';
import { mapValuesKey } from 'zustand-x';
import { AuthStore } from './auth';
import { MenuStore } from './menu';
export { useVisibilityRepersist } from './useVisibilityRepersist';

// Global store
export const rootStore = {
  auth: AuthStore,
  menu: MenuStore,
};

// AuthStore.store.subscribe((auth, previousAuth) => {
//   if (!previousAuth) {
//     return;
//   }
//   if (auth.user?.userId !== previousAuth.user?.userId) {
//     const actions = origStore.getActions();
//     if (!['admin', 'owner'].includes(auth.user?.type)) {
//       actions.sites.setActive(null);
//       actions.sites.setLastActive(null);
//     }
//   }
// });

/**
 * Global hook that returns the store object.
 * @returns {object} The store object.
 */
export const useStore = () => mapValuesKey('use', rootStore);

/**
 * Global hook that returns a tracked store using the `useTracked` function.
 * @returns {object} The tracked store.
 */
export const useTrackedStore = () => mapValuesKey('useTracked', rootStore);

// Global getter selectors
export const store = mapValuesKey('get', rootStore);

export const api = mapValuesKey('store', rootStore);

// Global actions
export const actions = mapValuesKey('set', rootStore);
