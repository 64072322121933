import { store } from '@webfx/core-web';
import api from './utils/api';

// setup base configuration
store.dispatch.config.setConfig({
  api,
  apiUpload: `${process.env.POI_APP_URL}/uploads`,
});

require('@webfx/common-web/src/config/routes');
require('@webfx/permissions-web/src/config/routes');
require('@webfx/marketingcloud-web/src/config/routes');
require('@webfx/contentgenius-web/src/config/routes');
require('@webfx/playbook-web/src/config/routes');
require('@webfx/competitorspy-web/src/config/routes');
require('@webfx/schedulerfx-web/src/config/routes');
