/**
 * Search Autocomplete
 */
import React, { forwardRef } from 'react';
import { Combobox as HeadlessCombobox } from '@headlessui/react';
import { VList } from 'virtua';
import { Icon } from '@webfx/core-web';
import { useThrottledCallback } from 'use-debounce';

import styles from './Combobox.module.css';

/**
 * A custom ComboBox component.
 *
 * @component
 * @param {Object[]} options - The options to be displayed in the ComboBox.
 * @param {Function} onChange - The callback function to be called when the value of the ComboBox changes.
 * @param {Function} onSelect - The callback function to be called when an option is selected.
 * @param {string} [placeholder=''] - The placeholder text to be displayed in the ComboBox input.
 * @param {boolean} [isLoading=false] - A flag indicating whether the ComboBox is in searching state.
 * @param {number} [delay=400] - The delay (in milliseconds) before triggering the onChange callback.
 * @param {Object} [throttleOptions={ leading: false, trailing: true }] - The options for throttling the onChange callback.
 * @param {Object} [virtualize=null] - The options for virtualizing the ComboBox options. Passes all options directly to virtua VList
 * @returns {JSX.Element} The ComboBox component.
 */
export function Combobox({
  options,
  onSearchChange = () => {},
  onChange,
  value,
  defaultValue,
  placeholder = '',
  isLoading = false,
  delay = 400,
  throttleOptions = { leading: false, trailing: true },
  virtualize = null,
}) {
  // const [value, setValue] = useState('');
  // const [showOptions, setShowOptions] = useState(false);

  // const onChangeValue = (value) => {
  //   onChange(value);
  //   setValue('');
  // };

  const debouncedSetSearch = React.useRef(
    useThrottledCallback(onSearchChange, delay, throttleOptions)
  ).current;

  /**
   * Handles the change event of the ComboBox input.
   *
   * @param {Object} event - The change event object.
   */
  async function handleChange(event) {
    // setShowOptions(event.target.value.length !== 0);
    debouncedSetSearch(event);
  }

  const OptionsComponent = virtualize
    ? forwardRef(function ({ children, className, ...props }, ref) {
        return (
          <div ref={ref} className={className}>
            <VList {...virtualize} {...props}>
              {children}
            </VList>
          </div>
        );
      })
    : 'div';

  return (
    <>
      <HeadlessCombobox value={value} defaultValue={defaultValue} onChange={onChange} nullable>
        <div className={styles.customSelectWrapper}>
          <div className={styles.customSelect}>
            <div className={styles.combobox}>
              <HeadlessCombobox.Input
                className={styles.input}
                placeholder={placeholder}
                autoComplete="off"
                onClick={() => {
                  onChange('');
                }}
                onChange={handleChange}
                displayValue={(option) => option?.label}
              />
              <HeadlessCombobox.Button className={styles.comboButton}>
                <Icon className={styles.icon}>expand_more</Icon>
              </HeadlessCombobox.Button>
            </div>
            <div className={styles.customOptions}>
              <HeadlessCombobox.Options as={OptionsComponent} className={styles.options}>
                {isLoading && options.length === 0 ? 'Searching...' : null}
                {options.map((option) => (
                  <HeadlessCombobox.Option key={option.value} value={option}>
                    {option.label}
                  </HeadlessCombobox.Option>
                ))}
              </HeadlessCombobox.Options>
            </div>
          </div>
        </div>
      </HeadlessCombobox>
    </>
  );
}
