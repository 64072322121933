import classnames from 'classnames';
import { Html, Icon } from '@webfx/core-web';
import UserAvatar from './UserAvatar';
import styles from './TeamBlock.module.css';

const TeamBlock = ({ team, variant = null, onUserClick, showSMEIcon = true, industry }) => {
  const isPrimary = variant === 'primary';
  const isWorkstack = variant === 'workstack';
  let bgStyle = {};
  if (isPrimary) {
    bgStyle = {
      backgroundImage: 'url(/assets/images/contributors/primary-team-bg.png)',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
    };
  }
  const industryHours = team.users.reduce((accum, u) => {
    if (u.industry_hours) {
      return accum + Math.ceil(u.industry_hours);
    }
    return accum;
  }, 0);
  return (
    <div
      className={classnames(
        styles.wrapper,
        team.users.length ? '' : 'bg-blue-00',
        'mb-4 p-3 rounded-sm'
      )}
      style={bgStyle}
    >
      <div
        className={classnames(
          styles.title,
          'd-flex flex-wrap align-items-center justify-content-center justify-content-lg-start mb-3 font-20'
        )}
      >
        <div className="d-flex align-items-center">
          <Icon className={isPrimary ? 'text-orange' : 'text-blue-vivid-200'}>{team.icon}</Icon>
          <span className={classnames(styles.teamName, 'ml-2 bold')} data-fx-name="teamName">
            {team.name}
          </span>
        </div>
        {!isWorkstack && (industryHours || team?.industry_hours) ? (
          <div className="d-flex align-items-center rounded-pill px-2 py-1 bg-blue-400 text-white">
            <Icon className="font-18">schedule</Icon>
            <span className="ml-2 font-14 text-uppercase">
              {isPrimary ? (
                <>
                  <strong>{industryHours.toLocaleString()} hours</strong> of {industry?.industry}{' '}
                  industry experience
                </>
              ) : (
                <>
                  <strong>{Math.ceil(team.industry_hours).toLocaleString()} hours</strong> of{' '}
                  {industry?.industry} industry experience
                </>
              )}
            </span>
          </div>
        ) : null}
      </div>
      {team.users.length ? (
        <div className="d-flex justify-content-center justify-content-lg-start">
          <div
            className={classnames(styles.avatarGrid, isWorkstack && styles.avatarGridWide)}
            data-fx-name="contributorsGrid"
          >
            {team.users.map((user, idx) => (
              <UserAvatar
                handleUserClick={onUserClick}
                user={user}
                variant={variant}
                key={`team-${idx}-${user.user_id}`}
                showSMEIcon={showSMEIcon}
              />
            ))}
          </div>
        </div>
      ) : (
        <div className="d-flex justify-content-center">
          <div
            className={classnames(
              styles.noResults,
              'd-flex flex-column align-items-center text-center'
            )}
          >
            <Icon className="text-gray-500 mb-2">groups</Icon>
            <Html str={team.no_results_html ?? 'Details to be populated soon'} />
          </div>
        </div>
      )}
    </div>
  );
};

export default TeamBlock;
