import { useEffect } from 'react';
import { BannerContext } from '@webfx/common-web';

/**
 * Uses the banner action
 *
 * @param {*} bannerDetails
 */
const useBanner = (bannerDetails = {}) => {
  const set = BannerContext.useStoreActions((actions) => actions.set);

  useEffect(() => {
    set(bannerDetails);

    return () => {
      set({});
    };
  }, [bannerDetails.route, bannerDetails.page]);
};

export default useBanner;
