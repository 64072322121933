import { Downloads, Imports, Preloader } from '@webfx/core-web';
import { MyInfo, AdminLayout, HelpCenter, HelpCenterV1 } from '@webfx/common-web';
import { useAuth, useLaunchDarkly, usePageView, useStartPage, useFlags } from '@webfx/web-hooks';
import TeamWork from '@webfx/teamwork-web';
import React, { Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

const ClientsPermissions = React.lazy(() => import('@webfx/permissions-web'));
const TeamAi = React.lazy(() => import('@webfx/teamai-web'));
const MarketingCloud = React.lazy(() => import('@webfx/marketingcloud-web'));
const ContentGenius = React.lazy(() => import('@webfx/contentgenius-web'));
const CompetitorSpy = React.lazy(() => import('@webfx/competitorspy-web'));
const Playbook = React.lazy(() => import('@webfx/playbook-web'));
const Scheduler = React.lazy(() => import('@webfx/schedulerfx-web'));

/**
 * Main Application Admin
 */
const Admin = () => {
  usePageView();
  useLaunchDarkly();
  const startPage = useStartPage();
  const { switchingUser, userId } = useAuth();
  const flags = useFlags();

  if (!userId) {
    return null;
  }

  if (switchingUser) {
    return <Preloader />;
  }

  return (
    <AdminLayout>
      <Downloads />
      <Imports />
      <Suspense fallback={<div style={{ display: 'none' }} />}>
        <Switch>
          <Redirect exact path="/" to={startPage} />;
          <Redirect from="/crm" to={'/nutshell'} />
          <Route path="/my-info" component={MyInfo} />
          <Route
            path="/help-center"
            component={flags.helpcenterUpdates ? HelpCenter : HelpCenterV1}
          />
          <Route path="/client-permissions" component={ClientsPermissions} />
          <Route path="/nutshell/:siteId?" component={MarketingCloud} />
          <Route path="/marketingcloudfx" component={MarketingCloud} />
          <Route path="/playbookfx" component={Playbook} />
          <Route path="/scheduler" component={Scheduler} />
          <Route path="/competitorspy" component={CompetitorSpy} />
          <Route path="/contentgenius/:siteId?" component={ContentGenius} />
          <Route path="/teamai" component={TeamAi} />
          <Route path="/" component={TeamWork} />
        </Switch>
      </Suspense>
    </AdminLayout>
  );
};

export default Admin;
