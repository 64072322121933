import { api } from '@webfx/core-web';
import useActiveSite from './useActiveSite';

/**
 * Returns the active sites Plan information
 *
 * @param {boolean} useIamPlans - if true, will return plans from the new iam/plans endpoint
 * @returns {object} - the active sites plan information
 */
export default function useSitePlan(useIamPlans = false) {
  const { siteId } = useActiveSite();
  const { data: sitePlan, isFetching } = api.useQuery(
    ['sites', siteId, { $join: ['plan'], $select: ['siteId'], $cache: false }],
    {
      enabled: !!siteId && !useIamPlans,
      refetchOnWindowFocus: false,
    }
  );

  const { data: [iamPlan] = [], isFetching: iamFetching } = api.useQuery(
    ['/iam/plans', { siteId }],
    {
      enabled: !!siteId && useIamPlans,
      refetchOnWindowFocus: false,
    }
  );

  if (isFetching || iamFetching) {
    return null;
  }

  return useIamPlans ? iamPlan : sitePlan?.plan;
}
