import { context } from '@webfx/core-web';
import classnames from 'classnames';
import { useStoreActions, useStoreState } from 'easy-peasy';
import React from 'react';
import { Link } from 'react-router-dom';

import styles from './DropdownItem.module.css';

const DropdownItem = React.forwardRef(
  ({ href = '', children, data, index, style, onClick, ...passThroughProps }, ref) => {
    const { ResizeContext } = context;
    const { url, active } = useStoreState((state) => state.router);
    // const router = useStoreState((state) => state.router);
    const setActiveSiteId = useStoreActions((actions) => actions.sites.setActive);
    const app = useStoreState((state) => state.router.app);

    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;
    const setSize = ResizeContext.useStoreActions((actions) => actions.setSize);

    React.useEffect(() => {
      if (resolvedRef.current) {
        setSize({
          id: index,
          size: resolvedRef.current.getBoundingClientRect().height,
        });
      }
    }, [index, resolvedRef, setSize]);

    let routeName = active;
    if (app === 'cgfx') {
      routeName = 'cgfxKanban';
    } else if (active === 'crmContact') {
      routeName = 'crmContacts';
    }

    // Update store state immediately to avoid race conditions
    const handleClick = () => {
      setActiveSiteId(data.siteId);
    };

    return (
      <div ref={resolvedRef} style={style} onClick={onClick}>
        <Link
          to={href || url({ route: routeName, siteId: data.siteId })}
          role="button"
          {...passThroughProps}
          key={`site-${data.siteId}`}
          data-fx-name="menuItems"
          className={classnames('d-block py-2 px-3 border-bottom', styles.link)}
          onClick={handleClick}
        >
          <h4 className="site-select-name">{children}</h4>
          <p className="site-select-url">{data.url.replace(/^http(s)?:\/\/(www\.)?/, '')} </p>
        </Link>
      </div>
    );
  }
);

export default DropdownItem;
