import classnames from 'classnames';
import { Icon, Spinner } from '@webfx/core-web';
import useLeadershipMembers from '../../hooks/useLeadershipMembers';
import styles from './KeyLeadership.module.css';
import LeaderCard from './LeaderCard';

const KeyLeadership = ({ companyId, industry, horizontal = false }) => {
  const { data: leadership, isLoading } = useLeadershipMembers(companyId);

  return (
    <>
      {isLoading ? (
        <Spinner small />
      ) : (
        <div
          className={classnames(
            'd-flex flex-column bg-blue-00 px-2 py-3',
            styles.leadershipSection
          )}
        >
          <div className="d-flex align-items-center mb-2">
            <Icon className="text-blue-vivid-300 font-24">flag</Icon>
            <span className="ml-2 bold text-gray-700" data-fx-name="teamName">
              {industry?.industry} Center of Excellence Leadership
            </span>
          </div>
          <div
            className={classnames(styles.leadersGrid, {
              [styles.horizontal]: horizontal,
            })}
            data-fx-name="leadersGrid"
          >
            {leadership?.map((leader, idx) => (
              <LeaderCard key={`leader-${idx}`} industry={industry.industry} leader={leader} />
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default KeyLeadership;
